import React from 'react';
import layoutStyles from '../../../stylesheets/components/layout.module.scss';
import textStyles from '../../../stylesheets/components/text.module.scss';
import { mainBody } from '../../../stylesheets/components/mainBody.module.scss';
import buttonStyles from '../../../stylesheets/components/buttons.module.scss';
import linkStyles from '../../../stylesheets/components/links.module.scss';
import glimmerStyles from '../../../stylesheets/components/glimmer.module.scss';
import classNames from 'classnames';
import CircleCheckLargeIcon from '../components/shared_icons/CircleCheckLargeIcon';
import { Button } from '@mui/material';

interface SuccessPageProps {
  successText: string;
  successSubText?: string;
  children?: React.ReactNode;
  altIcon?: React.ReactNode;
}

interface SuccessPageGlimmerProps {
  subText?: boolean;
  subButton?: boolean;
  subButtonText?: string;
}

const SuccessPage = ({
  successText,
  successSubText,
  children,
  altIcon,
}: SuccessPageProps) => {
  const icon = altIcon ? altIcon : <CircleCheckLargeIcon />;

  return (
    <main className={mainBody} id="success">
      <div
        className={classNames(
          layoutStyles.flex,
          layoutStyles.flexColumn,
          layoutStyles.alignCenter,
          layoutStyles.mt30
        )}
      >
        {icon}
        {successSubText && (
          <span
            className={classNames(
              textStyles.fs15,
              textStyles.colorSecondary,
              layoutStyles.mb6
            )}
          >
            {successSubText}
          </span>
        )}
        <h1 className={layoutStyles.mb30} data-test-class="success-text">
          {successText}
        </h1>
        {children}
      </div>
    </main>
  );
};

export const SuccessPageGlimmer = ({
  subText,
  subButton,
  subButtonText,
}: SuccessPageGlimmerProps) => {
  return (
    <main className={classNames(mainBody, 'main-glimmer')}>
      <div
        className={classNames(
          layoutStyles.flex,
          layoutStyles.flexColumn,
          layoutStyles.alignCenter,
          layoutStyles.mt30
        )}
      >
        <svg
          className={classNames(
            layoutStyles.mb30,
            glimmerStyles.glimmerElement
          )}
          width="62"
          height="62"
          viewBox="0 0 62 62"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        ></svg>
        {subText && (
          <span
            className={classNames(
              textStyles.fs15,
              layoutStyles.mb6,
              glimmerStyles.glimmerElement,
              glimmerStyles.glimmerParagraph
            )}
          ></span>
        )}
        <h1
          className={classNames(
            layoutStyles.mb30,
            glimmerStyles.glimmerElement
          )}
        ></h1>
        <Button
          className={classNames(
            buttonStyles.button,
            glimmerStyles.glimmerElement
          )}
        />
        {subButton && (
          <Button
            className={classNames(
              linkStyles.link,
              linkStyles.grey,
              layoutStyles.fullWidth,
              buttonStyles.modalButton,
              glimmerStyles.glimmerNoBackground
            )}
          >
            <span className={glimmerStyles.glimmerElement}>
              {subButtonText}
            </span>
          </Button>
        )}
      </div>
    </main>
  );
};

export default SuccessPage;
